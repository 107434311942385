<template>
  <m-container>
    <page-title previous-location :page-title="exam.title"></page-title>
    <m-container >
      <v-card flat class="pa-8 border border-solid border-radius-8 border-primary">
     
          <h2 class="mb-5">{{ exam.title }}</h2>
          <v-row :class="{'flex-column': $vuetify.breakpoint.mdAndDown }">
            <v-col class="d-flex">
              <h4 class="mb-2">Total Marks: &nbsp;</h4>
              <p>{{ exam.points }}</p>
            </v-col>
            <v-col class="d-flex">
              <h4 class="mb-2">Duration: &nbsp;</h4>
              <p>{{ exam.duration }} minutes</p>
            </v-col>
          </v-row>
          <h4 class="mb-2">Instruction:</h4>
          <p class="mb-3">{{ exam.instruction }}</p>
          <p :align="$vuetify.breakpoint.mdAndDown ? 'left': 'center'" class="mt-6">Click the start button to start the exam</p>
          <v-row justify="center" align="center" class="mb-6 flex-column">
            <v-btn
              depressed
              class="white--text bg-primary pa-6 px-11 mt-6 font-weight-bold font-size-20"
              @click="startExam"
              >Start Exam
            </v-btn>
              <v-btn v-if="examDetails.is_attempted" class=" pa-6 mt-4 font-size-20" @click="showHistory" depressed>
                <v-img :src="Icons.history" alt="History Icon" class="mr-2"></v-img>
                <p>Exam History</p>
              </v-btn>
          </v-row>
      </v-card>
    </m-container>
  </m-container>
</template>

<script>
import { ExamProvider } from "#ef/exam/mixins";
import historyIcon from "@ef/assets/history.svg";
import downIcon from "@ef/assets/down.svg";

export default {
  name: "ExamEntry",
  mixins: [ExamProvider],
  props: {
    examId: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      loadingExam: false,
      exam: undefined,
      allContents: null,
      examDetails: null,
    };
  },
  computed: {
    Icons() {
      return {
        history: historyIcon,
        down: downIcon,
      };
    },
  },
  async created() {
    this.exam = await this.getExamDetails()
    await this.retriveStudentsCourseDesign()
    this.extractHistoryData()
    if (!this.exam) {
      this.$router.go(-1)
    }
  },
  methods: {
    extractHistoryData() {
      this.allContents = this.$store.state.studentCourseDesignDashboard.moduleContents[this.$ielts_course_id]
      for (let key in this.allContents) {
        if (key === this.examId) {
          this.examDetails = this.allContents[key]
        }
      }
    },
    async startExam() {
      if (this.loadingExam) return;
      try {
        this.loadingExam = true;
        const { student_exam_id: studentExamId } = await this.$store.dispatch(
          "studentExam/start",
          {
            examId: this.exam.exam_id ?? this.exam.id,
            batchId: this.$ielts_batch_id
          }
        );
        if (this.exam.has_sections) {
          await this.$router.replace({
            name: "studentsAppearSectionExamRoutes",
            params: {
              appear: "appear",
              testId: studentExamId,
              sectionId: 0
            }
          });
        } else {
          await this.$router.push({
            name: "studentsAppearExamRoutes",
            params: { appear: "appear", testId: studentExamId }
          });
        }
      } catch (e) {
        this.$logger.log(e);
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.loadingExam = false;
      }
    },
    async retriveStudentsCourseDesign() {
      try {
        this.renderHtml = false;
        let studentId = this.$store.state.user.user.user_id;
         await this.$store.dispatch(
          "studentCourseDesignDashboard/getCourseDesign",
          {
            courseId: this.$ielts_course_id,
            studentId
          }
        );
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.renderHtml = true;
      }
    },
    async showHistory() {
      try {
        this.loadingHistory = true;
        await this.$router.push({
          name: "studentsAppearExamRoutes",
          params: {
            appear: "history",
            testId: this.examDetails.is_attempted ? this.examDetails.exam_history_id : "N/A"
          }
        });
      } catch (e) {
        this.$logger.log(e);
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.loadingHistory = false;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
