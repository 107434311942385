var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    [
      _c("page-title", {
        attrs: { "previous-location": "", "page-title": _vm.exam.title },
      }),
      _c(
        "m-container",
        [
          _c(
            "v-card",
            {
              staticClass:
                "pa-8 border border-solid border-radius-8 border-primary",
              attrs: { flat: "" },
            },
            [
              _c("h2", { staticClass: "mb-5" }, [
                _vm._v(_vm._s(_vm.exam.title)),
              ]),
              _c(
                "v-row",
                { class: { "flex-column": _vm.$vuetify.breakpoint.mdAndDown } },
                [
                  _c("v-col", { staticClass: "d-flex" }, [
                    _c("h4", { staticClass: "mb-2" }, [
                      _vm._v("Total Marks:  "),
                    ]),
                    _c("p", [_vm._v(_vm._s(_vm.exam.points))]),
                  ]),
                  _c("v-col", { staticClass: "d-flex" }, [
                    _c("h4", { staticClass: "mb-2" }, [_vm._v("Duration:  ")]),
                    _c("p", [_vm._v(_vm._s(_vm.exam.duration) + " minutes")]),
                  ]),
                ],
                1
              ),
              _c("h4", { staticClass: "mb-2" }, [_vm._v("Instruction:")]),
              _c("p", { staticClass: "mb-3" }, [
                _vm._v(_vm._s(_vm.exam.instruction)),
              ]),
              _c(
                "p",
                {
                  staticClass: "mt-6",
                  attrs: {
                    align: _vm.$vuetify.breakpoint.mdAndDown
                      ? "left"
                      : "center",
                  },
                },
                [_vm._v("Click the start button to start the exam")]
              ),
              _c(
                "v-row",
                {
                  staticClass: "mb-6 flex-column",
                  attrs: { justify: "center", align: "center" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "white--text bg-primary pa-6 px-11 mt-6 font-weight-bold font-size-20",
                      attrs: { depressed: "" },
                      on: { click: _vm.startExam },
                    },
                    [_vm._v("Start Exam ")]
                  ),
                  _vm.examDetails.is_attempted
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "pa-6 mt-4 font-size-20",
                          attrs: { depressed: "" },
                          on: { click: _vm.showHistory },
                        },
                        [
                          _c("v-img", {
                            staticClass: "mr-2",
                            attrs: {
                              src: _vm.Icons.history,
                              alt: "History Icon",
                            },
                          }),
                          _c("p", [_vm._v("Exam History")]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }